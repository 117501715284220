import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import tw from 'tailwind.macro'
import { media } from '../styles/utils'

import { Layout, PageHeader, CardItem, Testimonial, News, Pagination } from '../components'
import { container, padding } from '../styles/global'
import { parseACF } from '../utils'

const Investments = props => {
    const { data, group, index, first, last, pageCount } = props.pageContext
    // console.log('data', data)

    if (typeof group !== 'undefined') {
        group.forEach((item, i) => (item.acf = JSON.parse(item.acf_json)))
    }

    const breadcrumbs = {
        heading: {
            title: 'Invest with Strive',
            link: '/investments/'
        },
        description: {
            title: 'Completed Investments',
            link: undefined
        }
    }

    const renderInvestmentPosts = () => {
        return group.map((item, i) => {
            const first_row =
                (first && i === 0) || (!first && i === 0) || (!first && i === 1)
                    ? true
                    : false

            // console.log(item)
            return (
                <CardItem
                    key={i}
                    width={first && i === 0 ? 'full' : '1/2'}
                    first_row={first_row}
                    title={item.title}
                    slug={`/investments/${item.slug}/`}
                    cats={[item.acf.type]}
                    description={item.acf.listing_description}
                    map={item.acf.map}
                    subheading={item.acf.location}
                    read_more_text={`More Information`}
                    ribbon={item.acf.funded ? true : false}
                />
            )
        })
    }

    return (
        <Layout meta={data && data.seo}>
            {data && (
                <PageHeader
                    breadcrumbs={breadcrumbs}
                    hero_image={data.hero_image ? data.hero_image : undefined}
                    page_heading={
                        data.page_heading ? data.page_heading : undefined
                    }
                    short_intro={
                        data.short_intro ? data.short_intro : undefined
                    }
                />
            )}
            <Wrapper>
                <Container>
                    {typeof group !== 'undefined' && (
                        <>
                            <Grid>{renderInvestmentPosts()}</Grid>

                            {pageCount > 1 && (
                                <Pagination
                                    slug={`investments`}
                                    index={index}
                                    first={first}
                                    last={last}
                                    pageCount={pageCount}
                                />
                            )}
                        </>
                    )}
                </Container>
            </Wrapper>

            {data.testimonials && (
                <Testimonial
                    testimonials={data.testimonials}
                    category={'investor'}
                    bgColor={'dark'}
                />
            )}
            {data.news_posts && <News news_posts={data.news_posts} />}
        </Layout>
    )
}

const Wrapper = styled.div`
    ${tw`pb-24 bg-lightBlue`};
`

const Container = styled.div`
    ${container}
    ${padding}
`

const Grid = styled.div`
    ${tw`flex flex-wrap -mx-6`};
`
const Left = styled.div``
const Right = styled.div``

export default Investments
